import React from "react";
import Card from "react-bootstrap/Card";
import "./MuftiTaqi.css"
import NavbarAll from '../NavbarAll/NavbarAllFiles';
const FridayBayanComponent = () => {
 
      
  return (
    <>
    <NavbarAll/>
     
    <div className="video-container">
      <Card className="mufti-taqi-card" >
        <Card.Body>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/8FT31O4Pvv0?si=gxrjD7rBmRhGCraX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/M9v-2aOSx4E?si=H-zu4dwZECdANOqo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/HcKMV-62E58?si=JAP3Sthm8bKYXE2s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/sgT-h0rRABU?si=pb_zE93ZMLajvp11" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </Card.Body>
      </Card>
      
      
    </div>
    <div className="video-container">
      <Card className="mufti-taqi-card" >
        <Card.Body>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/RiCAQ4wMABU?si=Cvn5JXc3i4EthjoH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

        </Card.Body>
      </Card>
      
    
      
    </div>
    </>
  );
};

export default FridayBayanComponent;

