import React, { useState , useEffect} from "react";
import "./App.css";
import { initializeFirebase } from "./initializeFirebase";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HomeComponent from "./Components/HomeComponent/home";
import HazratBayanComponent from "./Components/HazratBayanComponent";
import BiographyComponent from "./Components/BiographyComponent/Biography";
// import BayanComponent from "./Components/BayanComponent";
import AskHazratComponent from "./Components/AskHazratComponent/AskHazrat";
import NaatComponent from "./Components/NaatComponent/Naat";
import Services from "./Components/Services2/Services";
import HazratBayan from "./Components/HazratBayan/HazratBayan";
import AddRecordingModal from "./Components/HazratBayanComponent/AddBayanComponent/AddBayanFriday";
import MuftiTaqi from "./Components/MuftiTaqiBayan/MuftiTaqi";
import AdminLogin from "./Components/LoginforAdmin/Login";
import UpdateJamaatTimings from "./Components/PrayerTimeComponent/UpdateJamatTimeAdmin";
import Footer1 from "./Components/FooterComponent/Footer1";
// import Banner from "./Components/Navbar/NavbarAbove";
import Activities from "./Components/Activities/Activities";
import Blog from "./Components/Blog/Blog"
import Contact from "./Components/Contact/Contact";
import FridayBayanComponent from "./Components/FridayBayanComponent/FridayBayan";
import SundayBayanComponent from "./Components/SundayBayanComponent/SundayBayan";
import ServicesDetailFuneral from "./Components/Services2/ServicesDetailFuneral";
import ServicesDetailQuranClasses from "./Components/Services2/ServicesDetailQuranClasses";
import ServicesDetailHelpingPoor from "./Components/Services2/ServicesDetailHelpingPoor";
import ServicesDetailMosqueDev from "./Components/Services2/ServicesDetailMosqueDev";
import AddBayanMorningDars from "./Components/HazratBayanComponent/AddBayanComponent/AddBayanMorningDars";
import UpdateandDeleteFridayBayan from "./Components/HazratBayanComponent/AddBayanComponent/UpdateandDeleteFridayBayan";
import UpdateandDeleteSundayBayan from "./Components/HazratBayanComponent/AddBayanComponent/UpdateandDeleteSundayBayan";
function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };
  useEffect(() => {
    initializeFirebase(); // Initialize Firebase and request FCM token
  }, []);
  const [value, setValue] = useState("1");
  return (
    <BrowserRouter forceRefresh>
    {/* <Banner/> */}
      {/* <Navbar /> */}
      <Routes>
        <Route
          path="/"
          element={<HomeComponent setValue={setValue} value={value} />}
        />
        <Route path="/update-jamat" element={isLoggedIn ? <UpdateJamaatTimings /> : <Navigate to="/login" />} />
        <Route path="/update-delete-morningfridaybayan" element={isLoggedIn ? <UpdateandDeleteFridayBayan /> : <Navigate to="/login" />} />
        <Route path="/update-delete-morningSundaybayan" element={isLoggedIn ? <UpdateandDeleteSundayBayan /> : <Navigate to="/login" />} />

        <Route path="/login" element={<AdminLogin onLogin={handleLogin} />} />
        {/* <Route path="/bayan" element={<BayanComponent />} /> */}
        <Route path="/moulanaabdulsattar" element={<HazratBayan />} />
        <Route path="/muftitaqi" element={<MuftiTaqi />} />
        <Route
          path="/hazrat"
          element={<HazratBayanComponent setValue={setValue} value={value} />}
        />
        <Route path="/fridaybayan" element={<FridayBayanComponent/>} />
        <Route path="/sundaybayan" element={<SundayBayanComponent/>} />

        <Route path="/seerat" element={<BiographyComponent />} />
        <Route path="/about" element={<AskHazratComponent />} />
        <Route path="/naat" element={<NaatComponent />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services-detail-funeral" element={<ServicesDetailFuneral />} />
        <Route path="/services-detail-quranclasses" element={<ServicesDetailQuranClasses />} />
        <Route path="/services-detail-helpingpoor" element={<ServicesDetailHelpingPoor />} />
        <Route path="/services-detail-mosquedev" element={<ServicesDetailMosqueDev />} />


        <Route path="/activities" element={<Activities />} />
        <Route path="/newbayan" element={isLoggedIn ? <AddRecordingModal />  : <Navigate to="/login" />} />
        <Route path="/newbayanmorning" element={isLoggedIn ? <AddBayanMorningDars /> : <Navigate to="/login" />} />

        {/* <Route path="/updatebayan" element={<AddRecordingModal />} /> */}
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/blog" element={<Blog/>} />
      </Routes>
      <Footer1 />
    </BrowserRouter>
  );
}

export default App;
