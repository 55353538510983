import { requestForToken } from "./firebase";
import axios from "axios";

const saveTokenToDatabase = async (token) => {
  try {
    const apiURl = process.env.REACT_APP_API_URL
    const response = await axios.post(`${apiURl}/save-token`, {
      token,
      userId: "user123", // Replace with actual user ID if available
    });
    console.log("Server response:", response.data);
  } catch (error) {
    console.error("Failed to save token to the database:", error);
  }
};

export const initializeFirebase = () => {
  requestForToken().then((token) => {
    if (token) {
      saveTokenToDatabase(token);
    } else {
      console.warn("No FCM token generated.");
    }
  });
};