import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NavbarAll from '../Navbar/NavbarAll';
const LoginContainer = styled.div`
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const LoginHeading = styled.h2`
  text-align: center;
  color: #333;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputLabel = styled.label`
  margin-bottom: 10px;
  color: #333;
`;

const InputField = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
`;

const SubmitButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
`;

const AdminLogin = ({ onLogin }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${apiUrl}/login`, {
        email,
        password,
      });

      if (response.status === 200) {
        // const userData = response.data;
        // console.log("logged in successfully")
        // console.log(userData)
        onLogin(); // Call the onLogin function passed from parent
        navigate("/update-jamat")
      } else {
        console.error('Authentication failed');
      }
    } catch (error) {
      console.error('Error during authentication:', error);
    }
  };

  return (
    <>
      <NavbarAll/>
      <LoginContainer>
        <LoginHeading>Login</LoginHeading>
        <LoginForm onSubmit={handleLogin}>
          <InputLabel>
            Email:
            <InputField type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </InputLabel>
          <InputLabel>
            Password:
            <InputField type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </InputLabel>
          <SubmitButton type="submit">Login</SubmitButton>
        </LoginForm>
        <p style={{ color: "black" }}>* Contact the organization for login details</p>
      </LoginContainer>
    </>
  );
};

export default AdminLogin;
