import React from 'react'
import FuneralService from "../../assets/audio/images/funeralservice.jpg"
import NavbarAll from '../NavbarAll/NavbarAllFiles';
const ServicesDetail = () => {
  return (
    <>
    <NavbarAll/>
    <section>
                <div class="w-100 pt-80 black-layer pb-70 opc65 position-relative">
                    <div class="fixed-bg" style={{backgroundColor:""}}></div>
                    <div class="container">
                        <div class="page-title-wrap text-center w-100">
                            <div class="page-title-inner d-inline-block">
                                <h1 class="mb-0">Services Detail</h1>
                                <ol class="breadcrumb mb-0">
                                    <li class="breadcrumb-item"><a href="/" title="Home">Maktab - Home</a></li>
                                    <li class="breadcrumb-item"><a href="/services" title="">Services</a></li>
                                    <li class="breadcrumb-item active">Detail</li>
                                    <li class="breadcrumb-item active">Funeral</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="w-100 pt-120 pb-250 position-relative">
                   
                    <div class="container">
                        <div class="services-detail-wrap w-100">
                            <div class="services-detail-info-wrap w-100">
                                <div class="row">
                                    <div class="col-md-6 col-sm-12 col-lg-6 order-md-1">
                                        <div class="service-detail-img position-relative w-100">
                                            <img class="img-fluid w-100" src={FuneralService} alt="Service Detai"/>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-12 col-lg-6">
                                        <div class="services-detail-info w-100">
                                            <i class="rounded-circle d-inline-block thm-bg flaticon-quran-2"></i>
                                            <h2 class="mb-0">Funeral Service</h2>
                                            <span class="d-block"></span>
                                            <ul class="services-info-list mb-0 list-unstyled">
                                                {/* <li>Recognition, drive special offers</li>
                                                <li>Launch a new product or communicate</li>
                                                <li>Message and generate action</li>
                                                <li>Campaign to powerfully convey</li>
                                                <li>Outdoor media, PR and digital marketing</li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="services-detail-desc w-100">
                                <p class="mb-0">Funeral in Islam (called Janazah in Arabic) follow fairly specific rights, though they are subject to regional interpretation and variation in customs. In all cases, sharia (Islamic religious law) calls for burial of the body, preceded by a simple ritual involving bathing and shrouding the body, followed by salah (prayer). Try to attend the Prayer of the deceased even if you not know him/her.</p>
                                <p class="mb-0">Masjid Zakaria works with a number of Islamic funeral Welfare Services that can arrange for the collection of the deceased from the hospital or mortuary and make arrangements for the funeral prayer and burial. These Islamic funeral Welfares provide a full range of funeral services including:</p>
                                <ul class="services-info-list mb-0 list-unstyled">
                                                <li>Transporting the body from home or hospital to Masjid</li>
                                                <li>Ghusal, Kafan and storage for the deceased.</li>
                                                <li>Janaza Salah</li>
                                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
   
    </>
  )
}

export default ServicesDetail
