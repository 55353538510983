import React , {useState, useEffect} from "react";
import Categories from "../Categories/Categories";
import "./home.css";
import HomePageView from "./HomePageView";
import Navbar from "../Navbar/Navbar3";
import HomeAudio from "./HomeAudio";
import { Link } from "react-router-dom";
// import Ramzan from "../../assets/audio/images/ramzantimings20243.jpg";
import ModalRamzanComponent from "../ModalRamzanComponent/ModalImage1";
// import Aitekaf from "../../assets/audio/images/Aitekaf 2024 Zakariya Masjid.pdf"
// import ModalRamzanComponent1 from "../ModalRamzanComponent/ModalImage";
// import Taraweeh from "../../assets/audio/images/masjid5.jpg";
import ArtCompetition from "../../assets/audio/images/artcompetition.jpg"
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";

function HomeComponent(props) {
  const [modalOpen, setModalOpen] = useState(false);
  // const [modalOpen1, setModalOpen1] = useState(false);

  // const handelbookclick = () => {
  //   window.open(ArtCompetition)
  // }
  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  // const openModal1 = () => {
  //   setModalOpen1(true);
  // };

  // const closeModal1 = () => {
  //   setModalOpen1(false);
  // };

  useEffect(() => {
    openModal(); // If you want to open the modal on mount, uncomment this line
  }, []);
  return (
    <>
    
      <Helmet>
        <title>Zakaria Masjid</title>
        <meta
          name="description"
          content="Welcome To Masjid Zakaria. Masjid Zakaria is committed to the Islamic way of life based on the Qur’an and the life examples of Prophet Muhammad."
        />
      </Helmet>
      <Navbar />
      <HomeAudio />
      <Categories />
      <HomePageView />
      {/* Static circle icon */}
      {/* {!modalOpen1 && (
      <div className="static-icon" onClick={openModal1}>
        <span className="icon-text">Summer Camp</span>
        <i className="fa fa-image"></i> 
      </div>
       )}  */}
      
      {/* Modal for displaying the image */}
        <ModalRamzanComponent
        show={modalOpen}
        onClose={closeModal}
        title="Art Contest"
        imageUrl={ArtCompetition}
      />  
       {/* <ModalRamzanComponent
        show={modalOpen1}
        onClose={closeModal1}
        title="Summer Camp"
        imageUrl={QuranClasses1}
      /> */}
    </>
  );
}

export default HomeComponent;
