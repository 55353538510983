import React from 'react';
import PropTypes from 'prop-types';
import './MyVerticallyCenteredModal.css'; // Assuming you have a CSS file for styli
import CloseIcon from '@mui/icons-material/Close';
// import QuranClasses1 from "../../assets/audio/images/eidmasjidbanner.jpg";

const Modal1 = ({ show, onClose, title, imageUrl }) => {
  if (!show) {
    return null;
  }

  const openImageFullScreen = () => {
    window.open("https://docs.google.com/forms/d/e/1FAIpQLSfQ91zKrV6CmF19AP1UuSApHtS7ktPVezhDySMxOPShVD1wJw/viewform?usp=send_form", '_blank');
  };

  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        <h2 className='modal-title'>{title}</h2>
        <CloseIcon className="modal-close-icon" onClick={onClose} /> {/* Cross icon */}
        <img
          src={imageUrl}
          alt={title}
          className="modal-image"
          onClick={openImageFullScreen}
        />
        <button style={{marginTop:"10px", backgroundColor:"#1e20a3", color:"white", padding:"10px", borderRadius:"8px"}} onClick={openImageFullScreen}>Register Now</button>
      </div>
    </div>
  );
};

Modal1.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

export default Modal1;
